
import React, { Component } from 'react'
import axios from '../constants/constants'
import { toaster } from 'evergreen-ui';

export class ContactUs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            subject: '',
            email: '',
            phone: '',
            message: '',
            errors: []

        }
    }

    hasError(key) {
        return this.state.errors.indexOf(key) !== -1;
    }

    handleChange = (evt) => {
        evt.preventDefault()
        const { name, value, } = evt.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = (evt) => {
        evt.preventDefault()

        let { name, subject, email, phone, message } = this.state

        let errors = [];

        //name
        if (this.state.name === "") {
            errors.push("name");
        }
        //subject
        if (this.state.subject === "") {
            errors.push("subject");
        }
        //phone
        if (this.state.phone.length != 10) {
            errors.push("phone");
        }
        //email
        const expression = /\S+@\S+/;
        let validEmail = expression.test(String(this.state.email).toLowerCase());

        if (!validEmail) {
            errors.push("email");
        }

        this.setState({
            errors: errors
        });

        if (errors.length > 0) {
            return false;
        } else {
            axios.post('/users/contactus', { name, subject, email, phone, message })
                .then(res => {
                    this.setState({
                        name: '',
                        subject: '',
                        email: '',
                        phone: '',
                        message: ''
                    })

                    toaster.success('Email has been sent successfully.')
                })
                .catch(err => {
                    this.setState({
                        name: '',
                        subject: '',
                        email: '',
                        phone: '',
                        message: ''
                    })

                    toaster.danger('Email cannot be sent.')
                })
        }
    }

    render() {
        return (
            <section className="ContactPage mb-5">
               <div class="pagetitle">
                <div class="bannerText ">
                    <h2>Get in touch with us now</h2>
                </div>
            </div>
                <div className="col-xl-9 col-lg-10 col-md-12 col-sm-12 mx-auto bg-white p-3 my-5 text-center">
                    <div className="row">
                        <div className="col-md-3 py-3 px-2 shadow-hover">
                            <div className="contactWrap">
                                <div className="contactWrapimg">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <div className="contactWrapText">
                                    {/* <p><strong>ANIC Head Office</strong></p> */}
                                    <p><strong>Head Office</strong></p>
                                    <span>
                                        <h6>Suite 3 20 Worth Street</h6>
                                        <h6>Chullora NSW 2190 Australia</h6>
                                    </span>
                                    {/* <br />
                                    <p><strong>Board of Imams VIC Office</strong></p>
                                    <a href="">
                                        <h6>945 Sydney Road, Coburg</h6>
                                        <h6>North VIC 3058</h6>
                                        <h6>Contact: 0433 924 197</h6>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 py-3 px-2 shadow-hover">
                            <div className="contactWrap">
                                <div className="contactWrapimg">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <div className="contactWrapText">                                
                                    <p> <strong>Landline Contact</strong> </p>
                                    <a href="">
                                        <h6>
                                            <strong>Telephone:</strong><a target="_blank" href="callto:(02) 0283774199"> (02) 8377 4199</a>
                                        </h6>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 py-3 px-2 shadow-hover">
                            <div className="contactWrap">
                                <div className="contactWrapimg">
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                </div>
                                <div className="contactWrapText">
                                    <p><strong>Online Contact</strong></p>
                                    <h6>
                                        <strong>Mail:</strong> <a target="_blank" href="mailto:info@reportislamophobia.com.au">info@reportislamophobia.com.au</a>
                                    </h6>
                                    <h6>
                                        <strong>Website:</strong> <a target="_blank" href="reportislamophobia.com.au">reportislamophobia.com.au</a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 py-3 px-2 shadow-hover">
                            <div className="contactWrap">
                                <div className="contactWrapimg">
                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                </div>
                                <div className="contactWrapText">                                
                                    <p> <strong>Social Media:</strong> </p>
                                    <h6>
                                    <a target="_blank" href="https://www.facebook.com/reportIslamophobia">
                                            <i aria-hidden="true" class="fa fa-facebook"></i>
                                        </a>
                                        <a target="_blank" href="https://www.instagram.com/reportislamophobia/">
                                            <i aria-hidden="true" class="fa fa-instagram"></i>
                                        </a>
                                        <a target="_blank" href="https://twitter.com/reportIslamo">
                                            <i aria-hidden="true" class="fa fa-twitter"></i>
                                        </a>
                                      
                                        <a target="_blank" href="https://www.youtube.com/channel/UCVfvTnv3SoH-qvwNlZNXHDw">
                                            <i aria-hidden="true" class="fa fa-youtube"></i>
                                        </a>
                                        <a target="_blank" href="https://www.linkedin.com/">
                                            <i aria-hidden="true" class="fa fa-linkedin"></i>
                                        </a>

                                        
                                        
                                    </h6>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <hr />
                </div>
                <div className="sec-title text-center col-md-12 py-3">
                    <div className="mb-5">
                        Send us a message
                    <hr />
                    </div>
                </div>
                <div className="col-md-6 mx-auto ContactForm">

                    <form>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <input
                                    type="text"
                                    className="form-control mb-3 pl-2"
                                    name="name"
                                    placeholder="Your Name*"
                                    required="true"

                                    onChange={this.handleChange}
                                />
                                <div
                                    className={
                                        this.hasError("name") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter a value
                            </div>
                            </div>
                            <div className="form-group col-md-6">
                                <input
                                    type="text"
                                    className="form-control mb-3 pl-2"
                                    name="subject"
                                    placeholder="Subject*"
                                    required="true"

                                    onChange={this.handleChange}
                                />
                                <div
                                    className={
                                        this.hasError("subject") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter a value
                            </div>
                            </div>
                            <div className="form-group col-md-6">
                                <input
                                    type="email"
                                    className="form-control mb-3 pl-2"
                                    name="email"
                                    placeholder="Email*"

                                    onChange={this.handleChange}
                                    required="true"
                                />
                                <div
                                    className={
                                        this.hasError("email") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter a valid email address
                            </div>
                            </div>
                            <div className="form-group col-md-6">
                                <input
                                    type="number"
                                    className="form-control mb-3 pl-2"
                                    name="phone"
                                    placeholder="Phone*"
                                    required="true"

                                    onChange={this.handleChange}
                                />
                                <div
                                    className={
                                        this.hasError("phone") ? "inline-errormsg" : "hidden"
                                    }
                                >
                                    Please enter phone number of 10 characters.
                            </div>
                            </div>
                            <div className="form-group col-md-12">
                                <textarea
                                    id="subject"
                                    name="message"
                                    placeholder="Write something.."
                                    className="w-100"
                                    required="true"

                                    onChange={this.handleChange}
                                />
                            </div>

                        </div>
                        <button className="btn btn-primary border-0 ThemePurpleBGColor px-5 rounded-0 submit mt-5" onClick={this.handleSubmit}>Submit</button>
                    </form>

                </div>
            </section>

        )
    }
}

export default ContactUs;
