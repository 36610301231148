import React from 'react'

export default function Contact() {
    return (
        <div class="homeContact">
            <div class="container">
                <div class="row mx-auto">
                    <div class="col-md-12">
                        <div class="contactheading">
                            <h5>contact us in Confidence</h5>
                        </div>
                    </div>
                    <div class="col-md-4  mb-5">
                        <div class="contactWrap">
                            <div class="contactWrapimg">
                            <a href="tel:(02)83774199">

                                <img src={require('../assets/images/Contact-Us/Contact_Icon_Phone.png')} alt="" />
                                </a>
                            </div>
                            <div class="contactWrapText">
                                <p>Phone</p>
                                <a href="tel:(02)83774199">
                                    <h6>(02) 8377 4199</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-md-4  mb-5">
                        <div class="contactWrap">
                            <div class="contactWrapimg">
                            <a href="mailto:info@reportislamophobia.com.au">
                                <img src={require('../assets/images/Contact-Us/Contact_Icon_Email.png')} alt="" />
                                </a>
                            </div>
                            <div class="contactWrapText">
                                <p>Email</p>
                                <a href="mailto:info@reportislamophobia.com.au">
                                    <h6>info@reportislamophobia.com.au</h6>
                                </a>
                            </div>
                        </div>
                    </div>

                    
                    <div class="col-md-4  mb-5">
                        <div class="contactWrap">
                            <div class="contactWrapimg">
                            <a href="https://www.facebook.com/reportIslamophobia" target="_blank">
                                <img src={require('../assets/images/Contact-Us/Contact_Icon_Facebook.png')} alt="" />
                                </a>
                            </div>
                            <div class="contactWrapText">
                                <p>Facebook</p>
                                <a href="https://www.facebook.com/reportIslamophobia" target="_blank">
                                    <h6>@islamophobiaAustralia</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                    <div class="col-md-4  mb-5">
                        <div class="contactWrap">
                            <div class="contactWrapimg">
                            <a href="https://www.instagram.com/reportislamophobia/" target="_blank">

                                <img src={require('../assets/images/Contact-Us/Contact_Icon_Instagram.png')} alt="" />
                                </a>
                            </div>
                            <div class="contactWrapText">
                                <p>Instagram</p>
                                <a href="https://www.instagram.com/reportislamophobia/" target="_blank">
                                    <h6>@reportislamophobia</h6>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4  mb-5">
                        <div class="contactWrap">
                            <div class="contactWrapimg">
                            <a href="https://twitter.com/reportIslamo" target="_blank">
                                <img src={require('../assets/images/Contact-Us/Contact_Icon_Twitter.png')} alt="" />
                                </a>
                            </div>
                            <div class="contactWrapText">
                                <p>Twitter</p>
                                <a href="https://twitter.com/reportIslamo" target="_blank">
                                    <h6>@islamopobiaAU</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                 

                    <div class="col-md-4  mb-5">
                        <div class="contactWrap">
                            <div class="contactWrapimg">
                            <a href="https://www.youtube.com/channel/UCVfvTnv3SoH-qvwNlZNXHDw" target="_blank">
                                <img src={require('../assets/images/Contact-Us/Contact_Icon_Youtube.png')} alt="" />
                                </a>
                            </div>
                            <div class="contactWrapText">
                                <p>Youtube</p>
                                <a href="https://www.youtube.com/channel/UCVfvTnv3SoH-qvwNlZNXHDw" target="_blank">
                                    <h6>@reportislamophobia.com.au</h6>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
