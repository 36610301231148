import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Report() {
    return (
        <div>
            {/* <div class="reports">
        <div class="container">
            <div class="recorsWrap">
                <div class="row">
                    <div class="col-md-3">
                        <div class="recordsBox">
                            <h2>200</h2>
                            <p>Different Suburbs Reported Incidents</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="recordsBox">
                            <h2>70%</h2>
                            <p>Victims are women and children</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="recordsBox box-2">
                            <h2>12,000</h2>
                            <p>Reports Submitted</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="recordsBox">
                            <h2>35%</h2>
                            <p>Submissions are reported to the Police</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}

             <div class="reportsSecond">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="reportsSecondHeading text-white">
                                <h4>You can report incidents of Islamophobia and anti-Muslim sentiments if you are a victim or a victim's friend/relative or a witness to the Islamophobic / anti-muslim behaviour directed at a victim, property or any incident</h4>
                            </div>
                        </div>
                        <div class="col-md-12 text-center text-white">
                            <NavLink to="/incident">
                                <button class="btn btn-primery register">Report</button>
                            </NavLink>
                        </div>
                    </div>
                </div>

            </div> 
 
        </div >
    )
}
