import React, { Component, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Axios from '../constants/constants';
import PaymentModal from '../components/PaymentModal';

import { logoutUser, setCurrentUser } from '../actions/authActions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ToastHeader } from 'reactstrap';
import StripeCheckout from 'react-stripe-checkout';
import { toaster } from 'evergreen-ui';

import { withRouter } from 'react-router'

class Header extends Component {
    constructor(props) {
        super(props)

        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            user: 'Login',
            status: 'Sign up',
            incidentCount: 0,

            setDropdownOpen: false,
            tokenID: null,
            tokenID_: null,
            showPaymentModal: false,
            collapsed: true,
        }
        //this.updateState()
    }
    toggle = () => this.setState({
        setDropdownOpen: !this.state.setDropdownOpen
    });

    updateState = () => {
        console.log('updatestate isAuth', this.props.auth.isAuthenticated)

        if (this.props.auth.isAuthenticated) {
            const token = localStorage.getItem('jwtToken')

            Axios
                .get('/users/me', { headers: { "Authorization": `${token}` } })
                .then(res => {
                    console.log(res.data.record1)
                    this.setState({
                        user: res.data.record1.firstname,
                        incidentCount: res.data.record1.incidentCount,
                        status: 'Log out'
                    })
                })
        }
    }
    componentDidMount = () => {
        console.log('cdm isAuth', this.props)
        if (this.props.auth.isAuthenticated) {
            const token = localStorage.getItem('jwtToken')

            Axios
                .get('/users/me', { headers: { "Authorization": `${token}` } })
                .then(res => {
                    console.log(res.data.record1)
                    this.setState({
                        user: res.data.record1.firstname,
                        incidentCount: res.data.record1.incidentCount,
                        status: 'Log out'
                    })
                })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        //this.updateState();
        //console.log('cwrp 1', nextProps.auth);
        //console.log('cwrp 2', nextProps.auth.user)

        if (nextProps.auth.isAuthenticated) {
            const token = localStorage.getItem('jwtToken');

            Axios
                .get('/users/me', { headers: { "Authorization": `${token}` } })
                .then(res => {
                    console.log(res.data.record1)
                    this.setState({
                        user: res.data.record1.firstname,
                        incidentCount: res.data.record1.incidentCount,
                        status: 'Log out'
                    })
                })
        }
    }

    handleClick = () => {
        this.setState({
            user: 'Login',
            status: 'Sign up'
        })

        this.props.logoutUser()
    }

    getToken = tokenID => {
        //console.log('stringify', JSON.stringify(tokenID))
        this.setState({
            tokenID: tokenID.id,
            tokenID_: tokenID.id
        })
    }

    onClosed = () => {
        //console.log('Syed Ali')
        //console.log('Token id', this.state.tokenID)
        if (this.state.tokenID_ !== null) {
            this.setState({
                showPaymentModal: true,
                tokenID: this.state.tokenID,
                tokenID_: null
            })
        }
    }

    onCheck = () => {
        toaster.warning('You need to sign in first.')
    }

    handleModalClose = () => {
        this.setState({
            showPaymentModal: false
        });
    };

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        const { logoutUser } = this.props;
        let userDropdownfunction = () => {
            this.setState({
                userDropdown: !this.state.userDropdown
            })
        }
        let userdrop = null

        if (this.state.userDropdown === true) {
            userdrop = (
                <div>Hello World</div>
            )
        }

        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

        return (
            <Fragment>
                <div>
                    <div className="headerMain">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 align-items-center d-flex">
                                    <div className="logo">
                                        <a href=""><img src={require('../assets/images/Islamophobia-Logo.png')} alt="" /></a>
                                    </div>


                                </div>
                                <div className="col-lg-8">
                                    <div className="headerMenu">
                                        <ul>
                                            <li><a href="/#/incident">Make a Report</a></li>

                                            {this.state.user != "Login" && <li><a href="/#/incidentslist">My Incidents ({this.state.incidentCount})</a></li>}
                                            <li><a href="/#/news/list">News</a></li>
                                            {/* <li><a href="">Security</a></li> */}
                                            {/* <li>
                                                {/* {localStorage.getItem('jwtToken') ?  */}
                                            {/* <StripeCheckout
                                                    stripeKey="pk_test_S6al3tVYaQwYAnUYloio8BI000ykQprOJJ"
                                                    token={this.getToken}
                                                    image={require('../assets/images/logo.png')}
                                                    name="Donate to Islamophobia Austrailia"
                                                    panelLabel="Donate Now"
                                                    closed={this.onClosed}
                                                    allowRememberMe={false}
                                                > */}
                                            {/* <a>Donate</a> */}
                                            {/* </StripeCheckout> */}
                                            {/* </StripeCheckout> : <a onClick={this.onCheck}>Donate</a>} */}
                                            {/* </li> */}
                                            <li>
                                                {this.state.user === "Login" ?
                                                    <NavLink className="nav-link" to="/users/login">Login</NavLink> :

                                                    <Dropdown isOpen={this.state.setDropdownOpen} toggle={this.toggle}>

                                                        <DropdownToggle caret className="dropdown-toggle border-0 text-white btn">
                                                            
                                                            {this.state.user}
                                                        </DropdownToggle>
                                                        <DropdownMenu className="border-0 rounded-0 shadow-sm mt-1">
                                                        <DropdownItem><NavLink to="/user">
                                                                My Profile
                                                            </NavLink></DropdownItem>
                                                            <DropdownItem ><NavLink to="/incidentslist">
                                                                My Incidents <span style={{}}>({this.state.incidentCount})</span>
                                                            </NavLink></DropdownItem>
                                                            {/* <DropdownItem divider /> */}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                }
                                            </li>
                                            <li>
                                                  {this.state.status === 'Sign up' ?
                                                    <a href="#/users/signup" className="nav-link">
                                    Sign up</a> :
                                                    <a href="/" className="nav-link " onClick={this.handleClick}>
                                    Log out
                                </a>}
                                                        </li>


                                                       
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {userdrop}
                </div>
                {this.state.showPaymentModal ?
                    <PaymentModal isOpen={this.onClosed} toggle={this.handleModalClose} body={this.state} /> : ''}
                <div class="mainMenu">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <nav class="navbar navbar-expand-lg navbar-light">
                                    {/* make this button collapsible */}
                                    <button onClick={this.toggleNavbar} className={`${classTwo}`} class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                                    <div className={`${classOne}`} class="collapse navbar-collapse justify-content-between" id="navbarNavDropdown">
                                        <ul class="navbar-nav mt-2 mt-lg-0">
                                            <li class="nav-item">
                                                <NavLink class="nav-link pl-lg-0" to="/">Home<span class="sr-only">(current)</span></NavLink>
                                            </li>
                                            <li class="nav-item">
                                                <NavLink to="/about" className="nav-link">About Us</NavLink>
                                            </li>
                                            <li class="nav-item">
                                                <NavLink to="/incident" className="nav-link">Report an Incident</NavLink>
                                            </li>
                                            {this.state.user != "Login" &&
                                                <li class="nav-item">
                                                    <NavLink to="/incidentslist" className="nav-link">My Incidents ({this.state.incidentCount})</NavLink>
                                                </li>
                                            }
                                            {/* <li class="nav-item">
                                                <NavLink to="/resources" className="nav-link">Resources</NavLink>
                                            </li> */}
                                            <li class="nav-item">
                                                <NavLink to="/news/list" className="nav-link">News</NavLink>
                                            </li>
                                            <li class="nav-item">
                                                <NavLink to="/PrivacyPolicy" className="nav-link">Privacy Policy</NavLink>
                                            </li>
                                            <li class="nav-item">
                                                <NavLink to="/contact" className="nav-link pr-0">Contact Us</NavLink>
                                            </li>
                                            {/* <li class="nav-item">
                                                    <div class="search-input">
                                                        <div id="search-form"><input id="search" type="text" name="search"/>
                                                            <label for="search">
                                                                <i aria-hidden="true" class="fa fa-search"></i>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>  */}

                                        </ul>
                                        <div className='mt-lg-0 mt-3'>
                                        <a href='https://apps.apple.com/us/app/islamophobia-register/id1531076195' className='mr-2' target="_blank"><img src={require('../assets/images/App-Store.svg')} width="150" alt="" className='mb-2 mb-lg-0' /></a>
                                        <a href='https://play.google.com/store/apps/details?id=com.islamophobia&hl=en&gl=US' target="_blank"><img src={require('../assets/images/Google-Play.svg')} width="150" alt="" className='mb-2 mb-lg-0' /></a>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        )
    }
}

// Header.propTypes = {
//     //classes: PropTypes.object.isRequired,
//     logoutUser: PropTypes.func.isRequired,
//     //user: PropTypes.object.isRequired
//   };

Header.propTypes = {
    history: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => { dispatch(logoutUser()) },
        setCurrentUser: (token) => { dispatch(setCurrentUser(token)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))