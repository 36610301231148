import React from 'react'

export default function Casestudies() {
    return (
        <div>
            <div class="caseStudies">
        <div class="row">
            <div class="col-md-12">
                <div class="caseStudiesHeading">
                    <h5>Reports and Case Studies</h5>
                    <hr/>
                </div>
            </div>
            <div class="col-md-3">
                <div class="imgBox">
                   <a href="https://web.facebook.com/reportIslamophobia?_rdc=1&_rdr" target="_blank"> <img src={require('../assets/images/Reports-and-Case-Studies/img-01.png')} alt=""/>
                    <div class="boxHover">
                        <p>Islamophobia / anti-Muslim Sentiment</p>
                    </div>
                    </a>

                </div>
            </div>
            <div class="col-md-3">
                <div class="imgBox">
                <a href="https://web.facebook.com/reportIslamophobia?_rdc=1&_rdr" target="_blank"> <img src={require('../assets/images/christchurch-victims.jpg')} alt=""/>
                    <div class="boxHover">
                        <p>Christchurch Massacre Victims</p>
                    </div>
                </a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="imgBox">
                <a href="https://web.facebook.com/reportIslamophobia?_rdc=1&_rdr" target="_blank"><img src={require('../assets/images/Reports-and-Case-Studies/img-03.jpg')} alt=""/>
                    <div class="boxHover">
                        <p>Islamophobia / anti-Muslim Sentiment</p>
                    </div>
                </a>
                </div>
            </div>
            <div class="col-md-3">
                <div class="imgBox">
                <a href="https://web.facebook.com/reportIslamophobia?_rdc=1&_rdr" target="_blank">  <img src={require('../assets/images/Reports-and-Case-Studies/img-04.jpg')} alt=""/>
                    <div class="boxHover">
                        <p>Islamophobia / anti-Muslim Sentiment</p>
                    </div>
                </a>
                </div>
            </div>
        </div>
    </div>
        </div>
    )
}
