import React from 'react'

export default function Footer() {
    return (
        <div class="footer">
            <div class="container">
                <div class="row item-align-center">
                    <div class="col-md-12">
                        <div class="footerWrap">
                            <div class="footerLogoImg">
                                <img src={require('../assets/images/Islamophobia-Logo-White.svg')} alt="" />
                            </div>
                            <div class="social">
                                {/* <div class="col-md-3">
                                    <img src={require('../assets/images/footer-icon1.png')} alt=""/>
                                </div> */}
                                <ul className="p-0 d-inline-flex">
                                <li className="px-2">
                                        <a href="https://www.facebook.com/reportIslamophobia" target="_blank">
                                            <i aria-hidden="true" class="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li className="px-2">
                                        <a href="https://www.instagram.com/reportislamophobia/" target="_blank">
                                            <i aria-hidden="true" class="fa fa-instagram"></i>
                                        </a>
                                    </li>
                               
                                    <li className="px-2">
                                        <a href="https://twitter.com/reportIslamo" target="_blank">
                                            <i aria-hidden="true" class="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li className="px-2">
                                        <a href="https://www.youtube.com/channel/UCVfvTnv3SoH-qvwNlZNXHDw" target="_blank">
                                            <i aria-hidden="true" class="fa fa-youtube"></i>
                                        </a>
                                    </li>
                                    <li className="px-2">
                                    <a target="_blank" href="https://www.linkedin.com/">
                                            <i aria-hidden="true" class="fa fa-linkedin"></i>
                                        </a>
                                        </li>
                                    </ul>
                                  
                            </div>
                            <div class='social'>
                            <ul className='p-0 d-inline-flex'>
                                        <li className='px-2'>
                                        <a href="https://apps.apple.com/us/app/islamophobia-register/id1531076195" class="mr-2" target="_blank"><img src={require('../assets/images/App-Store.svg')} width="150" alt="" class="mb-2 mb-lg-0" /></a>

                                        </li>
                                        <li className='px-2'>
                            <div class="mt-lg-0 mt-3"><a href="https://play.google.com/store/apps/details?id=com.islamophobia&amp;hl=en&amp;gl=US" target="_blank"><img src={require('../assets/images/Google-Play.svg')} width="150" alt="" class="mb-2 mb-lg-0" /></a></div>

                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
