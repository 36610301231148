import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const AboutUs = () => {
    return (
        <Fragment>
            <div class="pagetitle">
                <div class="bannerText ">
                    <h2>About Us</h2>
                </div>
            </div>

            <div class="whoarewe about-us my-5 position-relative">
                <div class="container">
                    <div class="row item-align-center">
                        <div class="col-md-7 pr-lg-5 card-body">
                            <div class="content  about-content">
                                <h2>About Islamophobia Australia</h2>
                                <h3>Catalyst for change</h3>
                                <br />
                                <p>Islamophobia Australia was founded by the Australian National Imams Council, a peak body that represents the interests of Muslims in Australia in 2021. Our primary objective is to provide Australian Muslims with a platform where their voices are heard, whereby any Islamophobic / anti-muslim sentiment incident(s) they have experienced or witnessed are recorded, published and followed up on with the relevant authorities.
                                </p>
                                <p>Our mission is to stand against any anti-Muslim sentiment  and play our part in making Australia a safe place for not just the Muslim community but also for minority communities. We hope to change the narrative and present to the broader public the real and authentic version of Australian Muslims who are often misrepresented both locally and globally. Such misrepresentations have been counter productive in delivering peace, harmony and tolerance within the Australian community. The reporting of Islamophobic / anti-muslim incidents also plays a key role in advocating and lobbying for legislative protections for minority communities on a state and national level.</p>
                                
                            </div>
                        </div>
                        <div class="col-md-5 card-body">
                            <div class="image about-img">
                                <img src={require('../assets/images/Aboutus/whoarewe.png')} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div class="IslamophobiaIncidents py-5">
                <div class="container">
                    <div class="row item-align-center my-5">
                        <div class="col-md-3">
                            <div class="row position-absolute top-0 pt-4 illustrationText">
                                <div class="col-md-12 text-right">
                                    <h5>
                                        <strong>Assaults or attacks on person of Muslim Background.</strong>
                                    </h5>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            </p>
                                </div>
                            </div>
                            <div class="row position-absolute bottom-0 pb-4 illustrationText">
                                <div class="col-md-12 text-right">
                                    <h5>
                                        <strong>Assaults or attacks on person of Muslim Background.</strong>
                                    </h5>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 illustration px-5">
                            <div class="illustrationImg">
                                <img src={require('../assets/images/Aboutus/Illustration.png')} alt="" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="row position-absolute top-0 pt-4 illustrationText">
                                <div class="col-md-12 text-left">
                                    <h5>
                                        <strong>Assaults or attacks on person of Muslim Background.</strong>
                                    </h5>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            </p>
                                </div>
                            </div>
                            <div class="row position-absolute bottom-0 pb-4 illustrationText">
                                <div class="col-md-12 text-left">
                                    <h5>
                                        <strong>Assaults or attacks on person of Muslim Background.</strong>
                                    </h5>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
<div className="about-us-points about-us ">
<div className="container">
<div class="row">
    <div className="col-lg-8 offset-lg-2 text-center">
    <p>To help address these issues, Islamophobia Australia seeks to document and publish incident reports we receive from people LIKE YOU. It is important that victims and witnesses of Islamophobic / anti-muslim attacks report their experience, however minor it is, to ensure that these issues are addressed on a law enforcement level. Here are some  examples of Islamophobic / anti-muslim incidents that you can report:</p>
                               
        </div>
    </div>
<div class="row">
    <div className="col-lg-8 offset-lg-2 text-center mt-3">
                                <ul>
                                    <li>
                                        •	Violent life-threatening attack on a Muslim
                            </li>
                                    <li>
                                        •	A physical attack that doesn’t pose a life-threat
                            </li>
                                    <li>
                                        •	Destruction of property resulting in financial loss
                            </li>
                                    <li>
                                        •	Hate speech or verbal abuse at any online or offline platform
                            </li>
                                    <li>
                                        •	Discrimination against Muslims in any type of private or public setting
                            </li>
                                </ul>
                              
    <p>
    If you experience or witness any of these incidents, we encourage you to report it by visiting our <a href='#/incident'>reporting section</a>. We urge you to contribute  towards our collective efforts in eliminating any hate crimes perpetrated against Australian Muslims and minority communities.

    </p>
                               
    
        </div>
    </div>
 

    </div>
  
    </div>
            <div class="whoarewe about-us my-5">
                <div class="container">
                    {/* <div class="row item-align-center">
                        <div class="col-md-4 ">
                            <div class="content ">
                                <h2>Islamophobia and Its Impact on Muslims </h2>
                            </div>
                        </div>
                        <div class="col-md-8">
                        <p>Since the inception of Islam, there have been people who hated Muslims on an extreme level. Wars against Muslims throughout history provide
                                a clear record of how wrongly Muslims are perceived just because they have different religious views. Now this ‘war’ has made its way among the civilians in the form of Islamophobia. Keep reading the guide to know more about it.</p>
                        </div>
</div> 

<div class="row">
    <div className="col-lg-12">
    <hr className="divider my-5" />

        </div>
    </div>
        {*/}



<div class="row item-align-center mt-3">
<div className="col-lg-6 mb-5">
<h4 className="mb-4">What is Islamophobia?</h4>
                                <p>Islamophobia, commonly understood as “anti-Muslim sentiment” is a form of exaggerated fear, hatred, and hostility toward Islam and Muslims that is perpetrated by negative stereotypes resulting in bias, discrimination, and the marginalizations and exclusion of Muslims from social, political, and civic life.</p>
                                <p>In short, Islamophobia / anti-muslim sentiment is a form of racism that includes various forms of violence, violations, discrimination and subordination that occur across multiple sites in response to the problematisation of Muslim identity.</p>

        </div>


        {/* <div className="col-lg-6  mb-5">
        <h4 className="mb-4">Where Did Islamophobia Come From?</h4>
                                <p>The term was introduced in 1991 by Runnymede Trust Report and was defined as the baseless hostility towards the Muslims, leading to fear and dislike of virtually all Muslims. Although this notion was given name in 1991, the groundless hatred against Muslims has been going on for centuries, especially in Western settings.</p>
                                <p>Furthermore, media plays a crucial role in brainwashing the people of Europe and some Asian countries against the ‘dangers’ the world is facing because of Muslims. </p>
                                <p>Plus, the keyboard warriors throughout social media also spew hatred against Muslims from time to time, leading to an increase in Islamophobia and the dangers Muslims face because of it.</p>
                                
        </div> */}

        <div className="col-lg-6  mb-5">
        <h4 className="mb-4">Common Dangers of Islamophobia / anti-Muslim sentiments Faced By Muslims:</h4>
                                <ul>
                                <li>
                                       <span>•</span>	Discrimination of Muslims in Public
                            </li>
                                    <li>
                                       <span>•</span>	Discrimination against Muslims at the Workplace
                            </li>
                                    <li>
                                    <span>•</span>	Muslim students are bullied in Educational Institutions
                            </li>
                                    <li>
                                    <span>•</span>	A rise in hate crimes against Muslims
                            </li>
                                    <li>
                                    <span>•</span> Muslims are perceived and treated with suspicion
                            </li>
                                    <li>
                                    <span>•</span>	Hate speech against Muslims through all sorts of media channels and platforms
                            </li>
                                    <li>
                                    <span>•</span>	Muslim women wearing hijab are the primary target seeing they are visibly Muslim and Vulnerable
                            </li>
                                </ul>
        </div>

        <div className="col-lg-6  mb-5">
            
        <h4 className="mb-4">Parting Words:</h4>
                                <p>It is important to acknowledge that Islamophobia / anti-muslim sentiment exists in Australia, and such sentiment can cause abuse and physical harm to Australian Muslims, especially women and children.</p>
                                <p>Further to this, a failure in acknowledging Islamophobia and addressing the perpetrator can lead to horrific outcomes and massacres such as Christchurch New Zealand. Therefore, Australian Muslims are encouraged to report their Islamophobic experiences on platforms such as Islamophobia Australia to ensure positive change occurs in addressing this issue. Not only are victims of Islamophobic attacks encouraged to report incidents, but also witnesses and bystanders, in order to improve third party perception towards incidents involving hate and discrimination.</p>
        </div>


                       
                    </div>
                </div>
            </div>

            {/* <div class="reportsThird">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-9">
                            <div class="reportsSecondHeading">
                                <h2>If you have experienced any islamophobic incidents,<br /> click here to report it.</h2>
                            </div>
                        </div>
                        <div class="col-lg-3">
                        <NavLink to="/incident">
                                <button class="btn btn-primary mb-3">Report Incident</button>
                            </NavLink>
                            <NavLink to="/incident">
                                <button class="btn btn-secondary">Contact Us Now</button>
                            </NavLink>
                        </div>
                       
                    </div>
                </div>

            </div> */}


            <div class="reportsSecond">
                <div class="container">
                    <div class="row">
                    <div class="col-md-12">
                            <div class="reportsSecondHeading text-white">
                                <h4>You can report incidents of Islamophobia and anti-Muslim sentiments if you are a victim or a victim's friend/relative or a witness to the Islamophobic / anti-muslim behaviour directed at a victim, property or any incident</h4>
                            </div>
                        </div>
                        <div class="col-md-12 text-center text-white">
                            <NavLink to="/incident">
                                <button class="btn btn-primery register">Report</button>
                            </NavLink>
                        </div>
                    </div>
                </div>

            </div> 
        </Fragment>
    )
}

export default AboutUs;