
import React, { Component } from 'react'
import axios from '../constants/constants'
import { toaster } from 'evergreen-ui';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

export class PrivacyPolicy extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            subject: '',
            email: '',
            phone: '',
            message: '',
            errors: []

        }
    }

    render() {
        return (
            <section className="ContactPage mb-5">

<div class="pagetitle">
                <div class="bannerText ">
                    <h2>Privacy Policy</h2>
                </div>
            </div>
                {/* 
                <div className="saferFuture mt-5">
                    <div className="container">
                        <div className="row item-align-center">
                            <div className="col-md-10">
                                <div className="content ">
                                    <h4>For a Safer Future</h4>
                                    <hr />
                                    <h2>Islamophobia Australia</h2>
                                    <h3>Catalyst for change</h3>
                                    <p>The Islamophobia Australia was founded on 2019 in response to the growing anecdotal evidence suggesting a rise in incidents of Islamophobia. The Islamophobia Australia was the first of its kind in Australia to provide a unique
                            platform for incidents of Islamophobia to be reported, recorded and analysed </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="saferFuture mt-5">
                    <div className="container">
                        <div className="row item-align-center">
                            <div className="col-md-12">
                                <div className="content ">
                                    <h2>Islamophobia Australia</h2>
                                    <p className="mb-5"> As a general rule, Islamophobia Australia will not disclose any of your personally identifiable information, except when we have your permission or under special circumstances, such as when we believe in good faith that the law requires it. </p>

                                    <Accordion defaultActiveKey="0">
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    <i class="fa fa-angle-down mr-3" aria-hidden="true"></i> What personally identifiable information is collected from me?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>The first time you get in touch with us- for example- when you sign up for email updates, register for an event or make a donation- we collect your personal information depending on the type of communication or transaction you opt for. The information may include your name, postal address, post code, telephone number, organization name, e-mail address, credit card, bank information or billing information. Please note, the personal details gathered by us may not be limited to above mentioned categories.</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
    <i class="fa fa-angle-down mr-3" aria-hidden="true"></i>How is the information collected used?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body> Islamophobia Australia uses the information as explained below unless you have specially notified us.
    <ul className="mt-2">
        <li>
            - We use your personal details to inform you about our services or products as per your request.
        </li>
        <li>
            - We may also use your information for administrative purposes which include updating you about any changes to our services or policies and creating a profile of your interests and preferences.
        </li>
        <li>
            - Upon your consent, we may also use your information to send you communications about our charitable work, campaigns, volunteering and fundraising activities.
        </li>
        <li>
            - We may send you direct mail that we feel may be of interest to you.
        </li>
        <li>
            - We do not sell your details to any third parties. The only exception to this is when you are using our secure online donation pages you are going through to a secure payment provider approved by the banking industry, that is, eWay.
        </li>
    </ul>
    <p> <strong>NB:</strong> For online credit/debit card transactions Islamophobia Australia enters your financial data directly to the payment service provider (eWay) and does not store any details of your credit/debit card on its system. In this way Islamophobia Australia abides by the strict industry-wide credit/debit card security standards (Payment Card Industry and Data Security Standards).
In the case of sensitive personal information, we will ask you to indicate your consent in allowing us to use it. </p>
<hr />
                                    <h5>We use the following methods to get your consent:</h5>
                                    <ul>
                                        <li>
                                            - Giving us your permission verbally.
                                        </li>
                                        <li>
                                            - Ticking a box; clicking an icon.
                                        </li>
                                        <li>
                                            - Sending an email; submitting a form; or subscribing to a service.
                                        </li>
                                    </ul>
                                    <p> When we seek your consent to using your personal information, we will tell you what we want to use it for and, other than as described in this policy, we won’t use it for any other purpose. </p>
                                    

</Card.Body>
    </Accordion.Collapse>
  </Card>


  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
    <i class="fa fa-angle-down mr-3" aria-hidden="true"></i>How we use cookies?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body>In regards to the websites, Islamophobia Australia uses cookies in order to provide you with a more personalized web service. <br /> A cookie is a text-only string of information that we pass to your computer’s hard disk through your web-browser so that the website can remember who you are. Cookies cannot be used by themselves to identify you. A cookie will typically contain the name of the domain from which the cookie has come, the “lifetime” of the cookie, and a value, usually a randomly generated unique number. For more information about cookies, please see <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>. </Card.Body>
    </Accordion.Collapse>
  </Card>


  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="3">
    <i class="fa fa-angle-down mr-3" aria-hidden="true"></i> What if I don’t want to receive cookies?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <Card.Body> Upon your first visit to our webpage, we will make a request to you to accept cookies. If you do not wish to receive cookies you can easily modify your web browser to refuse cookies, or to notify you when you receive a new cookie. However, you may not be able to use all the interactive features of our site if cookies are disabled, and so in order to enjoy the Islamophobia Australia site to the full, we recommend that you leave them switched on.</Card.Body>
    </Accordion.Collapse>
  </Card>


  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="4">
    <i class="fa fa-angle-down mr-3" aria-hidden="true"></i> How we protect your privacy on online community?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="4">
      <Card.Body>When you give us your personal details that information may be stored and processed outside of Australia and we take steps to ensure that your information is treated securely. Unfortunately, no data transmission over the Internet can be 100% guaranteed. As a result, while we strive to protect your personal information, we cannot ensure or warrant the security of any information you transmit to us, and you do so at your own risk. Once we receive your transmission, we make our best effort to ensure its security on our systems.</Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="5">
    <i class="fa fa-angle-down mr-3" aria-hidden="true"></i>External Links of our website?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="5">
      <Card.Body> Islamophobia Australia website may include links to other sites, not owned or managed by us. We cannot be held responsible for the privacy of information collected by websites not managed by us.</Card.Body>
    </Accordion.Collapse>
  </Card>

  <Card>
    <Accordion.Toggle as={Card.Header} eventKey="6">
    <i class="fa fa-angle-down mr-3" aria-hidden="true"></i> Amendments to the Privacy Policy?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="6">
      <Card.Body>We may change our policy from time to time so please visit our website and read through our privacy policy each time you share your personal information with us. If you do not agree to these changes, please do not continue to use the Islamophobia Australia website to submit personal information to us. If material changes are made to the Privacy Policy we will notify you by placing a prominent notice on the website.</Card.Body>
    </Accordion.Collapse>
  </Card>

</Accordion>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        )
    }
}

export default PrivacyPolicy;
